





























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Swiper from 'swiper';
import 'swiper/css/swiper.css';
import { EventBus } from '@/config/config';

@Component
export default class ThumbnailDetailModal extends Vue {
  @Prop()
  public title!: any;

  @Prop()
  public titleType!: any;

  @Prop()
  public thumbnail!: any;

  @Prop()
  public imgOne!: any;

  @Prop()
  public type!: string;

  public swiper: any;
  public thumbnailIndex: any = 0;

  public mounted() {
    this.activeSwiper(this.thumbnailIndex);
  }
  public updated() {
    EventBus.$on('thumbnailIndex', (data) => {
      this.thumbnailIndex = data;
    })
  }

  public activeSwiper(index) {
    if (this.swiper) {
      this.swiper.destroy();
      this.swiper = null;
    }
    const swiper = new Swiper('.modal-thumbnail-detail .swiper-container', {
      initialSlide: index,
      slidesPerView: 1,
      loop: true,
      pagination: {
        el: '.modal-thumbnail-detail .swiper-pagination',
        type: 'fraction',
      },
      observer: true,
      observeParents: true,
    });
  }

  public openListModal() {
    this.$modal.show('thumbnailList');
    this.$modal.hide('thumbnailDetail');
  }
  public modalOpened() {
    document.querySelector('html' as any).classList.add('open-modal');
    EventBus.$on('thumbnailIndex', (data) => {
      this.thumbnailIndex = data;
    })
    this.activeSwiper(this.thumbnailIndex);
  }
  public modalClosed() {
    document.querySelector('html' as any).classList.remove('open-modal');
    this.$emit('profileImgReset');
  }
  public destroyed() {
    document.querySelector('html' as any).classList.remove('open-modal');
  }
}
